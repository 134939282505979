<template>
  <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
    <div class="ui-layout-content--full ui-form-actions">
      <el-button type="primary" size="mini" @click="addHandle">{{'新建'}}</el-button>
      <el-button type="primary" size="mini" @click="editHandle">{{'编辑'}}</el-button>
      <el-button type="primary" size="mini" @click="showDetail">{{'详情'}}</el-button>
      <el-button type="primary" size="mini" @click="deleteHandle">{{'删除'}}</el-button>
    </div>
    <div class="ui-layout-content--full ui-layout-flex--1">
      <ex-search-table-pagination
        ref="searchTable"
        :fetch="$client.getRoleList"
        :columns="columns"
        :formOptions="formOptions"
        @selection-change="selectHandle"
      ></ex-search-table-pagination>
    </div>
    <el-dialog
      width="700px"
      :title="dialog.title"
      v-if="dialog.visible"
      :visible.sync="dialog.visible"
      :modal-append-to-body="true"
      :close-on-click-modal="false"
      :append-to-body="true"
      :show-close="true"
    >
      <role-management-form ref="editForm" :id="dialog.currentId" :type="dialog.type"></role-management-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModalHandle" size="mini">
            {{dialog.type === 'detail' ? '关闭' : '取消'}}
        </el-button>
        <el-button
          type="primary"
          @click="submitHandle"
          size="mini"
          v-if="dialog.type !== 'detail'"
        >{{'确定'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import RoleManagementForm from './RoleManagementForm';
export default {
    name: 'RoleManagement',
    components: {
        RoleManagementForm
    },
    data() {
        return {
            columns: [
                {
                    type: 'selection'
                },
                {
                    prop: 'code',
                    label: '代码'
                },
                {
                    prop: 'roleName',
                    label: '名称'
                },
                {
                    prop: 'roleTypeName',
                    label: '角色类型',
                    width: 100
                },
                {
                    prop: 'state',
                    label: '状态',
                    width: 100
                },
                {
                    prop: 'remark',
                    label: '描述',
                    width: 300
                },
                {
                    prop: 'createTime',
                    label: '创建时间'
                },
                {
                    prop: 'updateTime',
                    label: '更新时间'
                },
                {
                    prop: 'updaterName',
                    label: '更新人'
                }
            ],
            formOptions: {
                forms: [
                    {
                        prop: 'keyword',
                        label: '关键字',
                        placeholder: '请输入关键字',
                    }
                ]
            },
            selectedRows: [],
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                type: ''
            }
        };
    },
    methods: {
        selectHandle(val) {
            this.selectedRows = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
        addHandle() {
            this.dialog = {
                title: '新建',
                currentId: undefined,
                visible: true,
                type: 'add'
            };
        },
        editHandle() {
            if (this.handleValidate('edit')) {
                this.dialog = {
                    currentId: this.selectedRows[0].id,
                    title: '编辑',
                    visible: true,
                    type: 'edit'
                };
            }
        },
        deleteHandle() {
            if (this.handleValidate('delete')) {
                this.$confirm('确认删除？', {
                    type: 'warning'
                })
                    .then(() => {
                        const {selectedRows} = this;
                        const ids = selectedRows.map(item => item.id);
                        this.$client
                            .deleteRole(ids)
                            .then(() => {
                                this.$message({
                                    message: '操作成功！',
                                    type: 'success'
                                });
                                this.$refs.searchTable.searchHandler();
                            })
                            .catch(() => {
                                this.$message({
                                    message: '操作失败！',
                                    type: 'error'
                                });
                            });
                    });
            }
        },
        showDetail() {
            if (this.handleValidate('detail')) {
                this.dialog = {
                    currentId: this.selectedRows[0].id,
                    title: '详情',
                    visible: true,
                    type: 'detail'
                };
            }
        },
        handleValidate(type) {
            const {selectedRows} = this;
            const length = selectedRows.length;
            if (length < 1) {
                this.$message({
                    message: '请先选择待操作数据',
                    type: 'warning'
                });
                return false;
            }
            if ((type === 'edit' || type === 'detail') && length > 1) {
                this.$message({
                    message: '只允许对一条进行操作',
                    type: 'warning'
                });
                return false;
            }
            return true;
        },
        submitHandle() {
            this.$refs.editForm.submitHandle(rest => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        }
    }
};
</script>

