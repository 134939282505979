<template>
    <div>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
            :disabled="type === 'detail'"
        >
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'角色类型'" prop="roleTypeId">
                        <el-select
                            v-model="formData.roleTypeId"
                            :placeholder="'请选择'"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in keyValues.roleTypes"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'代码'" prop="code">
                        <el-input v-model="formData.code"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'语言'" prop="languageCode">
                        <el-select
                            v-model="formData.languageCode"
                            filterable
                            allow-create
                        >
                            <el-option
                                v-for="item in keyValues.languages"
                                :key="item"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'名称'" prop="name">
                        <el-input v-model="roleLanguage.name" style="width:100%" @change="changeName"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item
                        :label="'描述'"
                        prop="description"
                    >
                        <el-input
                            type="textarea"
                            :rows="3"
                            v-model="roleLanguage.description"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'状态'" prop="state">
                        <el-col>
                            <el-radio
                                v-model="formData.state"
                                label="Y"
                            >{{'启用'}}</el-radio>
                            <el-radio
                                v-model="formData.state"
                                label="N"
                            >{{'禁用'}}</el-radio>
                        </el-col>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'RoleManagementForm',
    props: {
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            formData: {
                roleTypeId: '',
                code: '',
                name: '',
                state: '',
                languageCode: '',
                roleLanguages: [
                    {languageCode: this.formData.languageCode, name: '', description: ''}
                ]
            },
            formRules: {
                roleTypeId: [
                    {
                        required: true,
                        message: '请选择角色类型',
                        trigger: 'blur'
                    }
                ],
                code: [
                    {
                        required: true,
                        message: '请输入代码',
                        trigger: 'blur'
                    }
                ],
                languageCode: [
                    {
                        required: true,
                        message: '请输入语言',
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                state: [
                    {
                        required: true,
                        message: '请选择状态',
                        trigger: 'blur'
                    }
                ]
            },
            keyValues: {
                roleTypes: [],
                languages: []
            }
        };
    },
    computed: {
        roleLanguage() {
            const roleLanguages = this.formData.roleLanguages.filter(e => e.languageCode === this.formData.languageCode);
            return roleLanguages[0];
        }
    },
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$client.getRoleType(),
                this.$client.getLanguages()
            ]);
            this.keyValues.roleTypes = data[0].items || [];
            this.keyValues.languages = data[1] || [];
        },
        async initFormValue() {
            await this.bindKeyValues();
            const {type, id} = this;
            if ((type === 'edit' || type === 'detail') && id != null) {
                this.getRole(this.id);
            }
        },
        getRole(id) {
            this.$client.getRole(id).then(data => {
                data.languageCode = this.keyValues.languages[0];
                this.formData = data || {};
            });
        },
        changeName(name) {
            this.formData.name = name;
        },
        submitHandle(cb) {
            this.$refs.form.validate(valid => {
                if (this.type === 'add') {
                    const tmp = {...this.formData};
                    delete tmp.id;
                    if (valid) {
                        this.$client.addRole(tmp).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            cb();
                        });
                    }
                } else if (valid) {
                    this.$client.editRole(this.formData).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        cb();
                    });
                }
            });
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
/deep/ [label][form] {
    width: 100%;
    text-align: left;
}
</style>
